<template>
	<router-view></router-view>
</template> 

<style lang="scss">
	/* 自定义css */
	@import "@/assets/css/common.css";
	@import "@/assets/css/flex.css";
	
	body{
		padding: 0;
		margin: 0;
		height: 100%;
	}
</style>
