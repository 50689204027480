import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App)

// element plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
app.use(ElementPlus,{
	locale: zhCn,
})


// VueEllipsis3
import VueEllipsis3 from 'vue-ellipsis-3';
app.use(VueEllipsis3);


// app.use(store)
app.use(router)
app.mount("#app")