const routes = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: "home" */"@/common/sign_in.vue")
	},
	{
		path: "/:catchAll(.*)",
		component: () => import(/* webpackChunkName: "home" */"@/components/error_description.vue")
	},
	// 后台路由
	{
		path: '/admin',
		component: () => import(/* webpackChunkName: "home" */"@/admin/main.vue"),
		props: true,
		children: [
			{
				path: 'index/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/index/index.vue"),
			},
			{
				path: 'user/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/user/index.vue"),
			},
			{
				path: 'user/prove',
				component: () => import(/* webpackChunkName: "home" */"@/admin/user/prove.vue"),
			},
			{
				path: 'user/prove/info',
				component: () => import(/* webpackChunkName: "home" */"@/admin/user/proveInfo.vue"),
			},
			{
				path: 'user/project',
				component: () => import(/* webpackChunkName: "home" */"@/admin/user/project.vue"),
			},
			{
				path: 'user/project/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/user/projectEdit.vue"),
			},
			{
				path: 'user/projectExchange',
				component: () => import(/* webpackChunkName: "home" */"@/admin/user/projectExchange.vue"),
			},
			{
				path: 'user/projectExchange/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/user/projectExchangeEdit.vue"),
			},
			{
				path: 'user/proveCompany',
				component: () => import(/* webpackChunkName: "home" */"@/admin/user/proveCompany.vue"),
			},
			{
				path: 'user/proveBank',
				component: () => import(/* webpackChunkName: "home" */"@/admin/user/proveBank.vue"),
			},
			{
				path: 'setup/config',
				component: () => import(/* webpackChunkName: "home" */"@/admin/setup/config.vue"),
			},
			{
				path: 'setup/wxapp',
				component: () => import(/* webpackChunkName: "home" */"@/admin/setup/wxapp.vue"),
			},
			{
				path: 'payment/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/payment/index.vue"),
			},
			{
				path: 'payment/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/payment/edit.vue"),
			},
			{
				path: 'payment/setup',
				component: () => import(/* webpackChunkName: "home" */"@/admin/payment/setup.vue"),
			},
			{
				path: 'diy/banner',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/banner.vue"),
			},
			{
				path: 'diy/banner/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/bannerEdit.vue"),
			},
			{
				path: 'diy/ad',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/ad.vue"),
			},
			{
				path: 'diy/ad/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/adEdit.vue"),
			},
			{
				path: 'diy/icon',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/icon.vue"),
			},
			{
				path: 'diy/icon/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/iconEdit.vue"),
			},
			{
				path: 'cron/tasks',
				component: () => import(/* webpackChunkName: "home" */"@/admin/cron/tasks.vue"),
			},
			{
				path: 'cron/tasks/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/cron/tasksEdit.vue"),
			},
			{
				path: 'cron/tasks/log',
				component: () => import(/* webpackChunkName: "home" */"@/admin/cron/log.vue"),
			},
			{
				path: 'bank/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/bank/index.vue"),
			},
			{
				path: 'bank/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/bank/edit.vue"),
			},
			{
				path: 'company/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/company/index.vue"),
			},
			{
				path: 'company/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/company/edit.vue"),
			},
			{
				path: 'withdrawal/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/withdrawal/index.vue"),
			},
			// {
			// 	path: 'product/bank/edit',
			// 	component: () => import(/* webpackChunkName: "home" */"@/admin/product/bankEdit.vue"),
			// },
			{
				path: 'product/bankList',
				component: () => import(/* webpackChunkName: "home" */"@/admin/product/bankList.vue"),
			},
			{
				path: 'product/user',
				component: () => import(/* webpackChunkName: "home" */"@/admin/product/user.vue"),
			},
			{
				path: 'product/user/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/product/userEdit.vue"),
			},
			{
				path: 'product/project',
				component: () => import(/* webpackChunkName: "home" */"@/admin/product/project.vue"),
			},
			{
				path: 'product/project/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/product/projectEdit.vue"),
			},
			{
				path: 'information/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/information/index.vue"),
			},
			{
				path: 'information/index_bank',
				component: () => import(/* webpackChunkName: "home" */"@/admin/information/index_bank.vue"),
			},
			{
				path: 'information/form1',
				component: () => import(/* webpackChunkName: "home" */"@/admin/information/form1.vue"),
			},
			{
				path: 'information/form2',
				component: () => import(/* webpackChunkName: "home" */"@/admin/information/form2.vue"),
			},
			{
				path: 'information/form3',
				component: () => import(/* webpackChunkName: "home" */"@/admin/information/form3.vue"),
			},
			{
				path: 'information/form4',
				component: () => import(/* webpackChunkName: "home" */"@/admin/information/form4.vue"),
			},
		]
	},
	// 银行路由
	{
		path: '/bank',
		component: () => import(/* webpackChunkName: "home" */"@/bank/main.vue"),
		props: true,
		children: [
			{
				path: 'index/index',
				component: () => import(/* webpackChunkName: "home" */"@/bank/index/index.vue"),
			},
			{
				path: 'user/auth',
				component: () => import(/* webpackChunkName: "home" */"@/bank/user/auth.vue"),
			},
			{
				path: 'user/auth/edit',
				component: () => import(/* webpackChunkName: "home" */"@/bank/user/authEdit.vue"),
			},
			{
				path: 'product/bank',
				component: () => import(/* webpackChunkName: "home" */"@/bank/product/bank.vue"),
			},
			{
				path: 'product/bank/edit',
				component: () => import(/* webpackChunkName: "home" */"@/bank/product/bankEdit.vue"),
			},
			{
				path: 'user/prove',
				component: () => import(/* webpackChunkName: "home" */"@/bank/user/prove.vue"),
			},
		]
	},
	// 公司路由
	{
		path: '/company',
		component: () => import(/* webpackChunkName: "home" */"@/company/main.vue"),
		props: true,
		children: [
			{
				path: 'index/index',
				component: () => import(/* webpackChunkName: "home" */"@/company/index/index.vue"),
			},
			{
				path: 'information/index',
				component: () => import(/* webpackChunkName: "home" */"@/company/information/index.vue"),
			},
			{
				path: 'information/info',
				component: () => import(/* webpackChunkName: "home" */"@/company/information/info.vue"),
			},
			{
				path: 'information/transfer',
				component: () => import(/* webpackChunkName: "home" */"@/company/information/transfer.vue"),
			},
			{
				path: 'information/delivery',
				component: () => import(/* webpackChunkName: "home" */"@/company/information/delivery.vue"),
			},
			{
				path: 'information/delivery/info',
				component: () => import(/* webpackChunkName: "home" */"@/company/information/delivery_info.vue"),
			},
			{
				path: 'user/prove',
				component: () => import(/* webpackChunkName: "home" */"@/company/user/prove.vue"),
			},
			{
				path: 'config/index',
				component: () => import(/* webpackChunkName: "home" */"@/company/config/index.vue"),
			}
		]
	},
]
export default routes //导出
