import { createRouter, createWebHashHistory } from "vue-router";
import routes from './router.js'

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 路由守卫 to到哪儿  from从哪儿离开  next跳转 为空就是放行
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : '优金准';
	//如果跳转为登录，就放行 
	if (to.path === '/') {
		next();
	}else {
		let token = localStorage.getItem('token');
		if (token == null || token === '') {
			next({path: '/'});
		}else{
			next();
		}
	}
});

export default router;
 